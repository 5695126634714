import { DialogUtility } from "@syncfusion/ej2-popups";
import * as R from "ramda";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFCheckBox from "../../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDetailsField from "../../../../RAFComponents/Inputs/RAFDetailsField";
import RAFDropdownCC from "../../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm, {
  Condition,
  ConditionNotNullOrWhiteSpace,
  WhenFieldChanges,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFStartDateEndDatePicker from "../../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import {
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecordRemoveFocus,
  IsSuperAdmin,
  RetrieveRecord,
  getAllFormsByCategoryType,
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  getTimeDifferenceInMinutes,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { LookUpGroupRow } from "../../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../../constants/Common/EntityConstants";
import { initUserAndTeam } from "../../../../helpers/ACutils";
import CareInterventionSupportContent from "../../../CareESIO/CareInterventionSupport/CareInterventionSupportContent";
import { getCareInterventionSupportListByCareRecipientUID } from "../../../CareESIO/CareInterventionSupport/CareInterventionSupportHelper";
import {
  getAllTravelVariantLookUpItems,
  getServiceContractItemVariant,
} from "../../../CareESIO/CareServiceContractItem/CareServiceContractItemHelper";
import { ServiceContractItemRow } from "../../../CareESIO/CareServiceContractItem/ServiceContractItem";
import { getShiftActivitiesByTaskId } from "../../../CareESIO/CareShiftLog/CareShiftLogHelper";
import ShiftActivityCardContent from "../../../CareESIO/CareTask/ShiftActivityCardContent";
import { addUserToCareTeam } from "../../../CareESIO/CareTeam/CareTeamHelper";
import RAFEntityProvider from "../../../Common/Providers/RAFEntityProvider";
import { getUserTenantSettings } from "../../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../../Common/TenantSettings/TenantSettingsRow";
import { getPlannedShiftTasksByRelatedToUID } from "../../Task/TaskHelper";
import { TaskRow } from "../../Task/TaskRow";
import {
  RAFServiceTransactionShiftType,
  RAFServiceTransactionType,
  SaveShiftPlannedActivityList,
  ServiceTransactionStates,
  getServiceTransactionDisplayNameFromStorage,
  getServiceTransactionTitleByStartEndDate,
  saveServiceTransaction,
  saveShiftActivityList,
} from "../ServiceTransactionHelper";
import { ServiceTransactionRow } from "../ServiceTransactionRow";
import "./ServiceTransactionActionsStyle.scss";
import ServiceTransactionSelectAssignee from "./ServiceTransactionSelectAssignee";
import RAFNumber from "../../../../RAFComponents/Inputs/RAFNumber";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";

class StepperItemsRow {
  id?: string;
  text?: string;
  index?: number;
}

export class RAFStepperConstant {
  static stepperConstant(Id: string, DisplayName: string) {
    return { Id, DisplayName };
  }

  static Home = RAFStepperConstant.stepperConstant("Home", "Shift Details");
  static SetAssignee = RAFStepperConstant.stepperConstant(
    "SetAssignee",
    "Select Assignee"
  );
  static SelectActivities = RAFStepperConstant.stepperConstant(
    "SelectActivities",
    "Add Supports"
  );
  static Billing = RAFStepperConstant.stepperConstant("Billing", "Billing");
}

interface IProps {
  isActive: boolean;

  objectUID?: string;
  selectedTaskObject?: ServiceTransactionRow;
  startDate?: Date;
  endDate?: Date;
  startEndDateDurationHrs?: number;

  onSave: () => void;
  defaultServiceTransactionType?: RAFServiceTransactionType;

  relatedTo?: string;
  relatedToUID?: string;
  relatedToType?: string;

  assigneeUID?: string;
  assignee?: string;

  loginUserUID?: string;
  loginUserName?: string;

  showRelatedToLookUp?: boolean;

  mode: "Create" | "Edit" | "Manage" | "SetAssignee";

  setDefaultAssigneeValue?: boolean;

  onDelete?: () => void;
  isNestedDlg?: boolean; //temporary fix for nested dialog
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  serviceTransactionRow: ServiceTransactionRow;
  careInterventionSupportRows: any[];
  shiftActivities: any[];
  plannedActivities?: TaskRow[];
  usersAndTeams: LookUpGroupRow[];
  formLibrary: FormLibraryRow;
  variantServiceContractItem?: ServiceContractItemRow;
  tenantSettings: TenantSettingsRow;

  activeTabIndex: StepperItemsRow;
  selectedTabId: string;
}

function ManageServiceTransaction({
  setDefaultAssigneeValue = true,
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const moduleName = RAFModuleName.ServiceTransaction;

  const stepperItems: StepperItemsRow[] = [
    {
      id: RAFStepperConstant.Home.Id,
      text: isNotNullAndUndefined(props.defaultServiceTransactionType)
        ? `${props.defaultServiceTransactionType} Details`
        : RAFStepperConstant.Home.DisplayName,
      index: 0,
    },
    {
      id: RAFStepperConstant.SetAssignee.Id,
      text: RAFStepperConstant.SetAssignee.DisplayName,
      index: 1,
    },
    {
      id: RAFStepperConstant.SelectActivities.Id,
      text: RAFStepperConstant.SelectActivities.DisplayName,
      index: 2,
    },
    {
      id: RAFStepperConstant.Billing.Id,
      text: RAFStepperConstant.Billing.DisplayName,
      index: 3,
    },
  ];

  let deleteDialog: any;
  let careTeamMemberAlertDialog: any;
  let assigneAlertWarningDialog: any;
  let durationAlertWarningDialog: any;
  const outerDivId = `raf_dlg_Outer_Div_${RAFEntityName.ServiceTransaction}`;
  let rafFormRef: FormRenderProps | null;
  const serviceTransactionDisplayName =
    getServiceTransactionDisplayNameFromStorage();

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: false,
      serviceTransactionRow: null,
      careInterventionSupportRows: [],
      shiftActivities: [],
      plannedActivities: [],
      usersAndTeams: null,
      formLibrary: null,
      tenantSettings: null,
      variantServiceContractItem: null,
      activeTabIndex: stepperItems[0],
      selectedTabId: RAFEntityName.Task,
    }
  );

  useEffect(() => {
    refesh();
  }, []);

  async function checkScrollable() {
    const div = await document.querySelector(
      "#raf_tab_header_service_transaction_action"
    );
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_service_transaction_action_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_service_transaction_action_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  const refesh = async () => {
    const { isActive, selectedTaskObject, mode } = props;
    if (isActive) {
      setState({ isLoading: true });
      if (mode === "Edit" || mode === "Manage" || mode === "SetAssignee") {
        const serviceTransactionRow: ServiceTransactionRow =
          isNotNullAndUndefined(selectedTaskObject)
            ? Object.fromEntries(
                Object.entries(selectedTaskObject).filter(
                  ([key, value]) => value !== null && value !== undefined //remove null or undefined data from object
                )
              )
            : await RetrieveRecord(
                props.objectUID,
                RAFEntityName.ServiceTransaction
              );

        if (
          isNotNullAndUndefined(serviceTransactionRow) &&
          isNotNullAndUndefined(serviceTransactionRow.UID)
        ) {
          serviceTransactionRow.ShowEmployeeCareTeam =
            CareEsioEntity.CareTeam.EntityName;
          serviceTransactionRow.ShowEmployeeCareTeamOnlyAvailable = false;

          serviceTransactionRow.IsPassive =
            serviceTransactionRow.ShiftType ===
            RAFServiceTransactionShiftType.Passive
              ? true
              : false;

          const [
            usersAndTeams,
            allForms,
            plannedActivities,
            careInterventionSupportRows,
            shiftActivities,
            tenantSettings,
          ] = await Promise.all([
            initUserAndTeam(),
            getAllFormsByCategoryType(CareEsioEntity.CareShiftLog.DisplayName),
            getPlannedShiftTasksByRelatedToUID(props.relatedToUID),
            getCareInterventionSupportListByCareRecipientUID(
              props.relatedToUID,
              CareEsioEntity.CareInterventionSupport.EntityName
            ),
            getShiftActivitiesByTaskId(serviceTransactionRow.UID),
            getUserTenantSettings(),
          ]);

          const formLibrary = isNotEmptyArray(allForms)
            ? allForms.find(
                (x) => x.Entity === CareEsioEntity.CareShiftActivity.EntityName
              )
            : null;

          let filteredCareInterventionSupports: any[] = [];
          let careInterventionSupportRowsClone = R.clone(
            careInterventionSupportRows
          );
          if (isNotEmptyArray(shiftActivities)) {
            filteredCareInterventionSupports =
              careInterventionSupportRowsClone &&
              careInterventionSupportRowsClone.filter((x) => {
                return !shiftActivities.some(
                  (activity) => activity["support_needuid"] === x.UID
                );
              });
          } else {
            filteredCareInterventionSupports = careInterventionSupportRows;
          }

          setState({
            serviceTransactionRow,
            isLoading: false,
            usersAndTeams,
            formLibrary,
            tenantSettings,
            plannedActivities,
            careInterventionSupportRows,
            shiftActivities,
            noContent: false,
            activeTabIndex:
              mode === "Edit"
                ? stepperItems[0]
                : mode === RAFStepperConstant.SetAssignee.Id
                ? stepperItems[1]
                : mode === "Manage"
                ? stepperItems[2]
                : mode === RAFStepperConstant.Billing.Id
                ? stepperItems[3]
                : stepperItems[0],
          });
        } else {
          setState({ isLoading: false, noContent: true });
        }
      } else if (mode === "Create") {
        const serviceTransactionRow = new ServiceTransactionRow();
        serviceTransactionRow.Notify = false;
        serviceTransactionRow.Type = isNotNullAndUndefined(
          props.defaultServiceTransactionType
        )
          ? props.defaultServiceTransactionType
          : RAFServiceTransactionType.Shift;
        if (serviceTransactionRow.Type === RAFServiceTransactionType.Booking) {
          serviceTransactionRow.Status = RAFShiftActionStatus.Completed;

          // InvoiceTransaction will be created only when IsBillable, IsAutoInvoice set to true.
          //in future this data will come from tenantsettings
          serviceTransactionRow.IsBillable = false;
          serviceTransactionRow.IsAutoInvoice = false;
        } else {
          // InvoiceTransaction will be created only when IsBillable, IsAutoInvoice set to true.
          //in future this data will come from tenantsettings
          serviceTransactionRow.IsBillable = true;
          serviceTransactionRow.IsAutoInvoice = true;
          serviceTransactionRow.Status = RAFShiftActionStatus.Scheduled;
        }
        serviceTransactionRow.BillingStatus = RAFShiftActionStatus.NotReady;
        serviceTransactionRow.State = ServiceTransactionStates.Draft;
        serviceTransactionRow.ShowEmployeeCareTeam =
          CareEsioEntity.CareTeam.EntityName;
        serviceTransactionRow.ShowEmployeeCareTeamOnlyAvailable = false;
        serviceTransactionRow.ShiftType = RAFServiceTransactionShiftType.Active;
        serviceTransactionRow.IsPassive = false;

        if (isNotNullAndUndefined(props.loginUserUID)) {
          serviceTransactionRow.AssigneeUID = props.loginUserUID;
          serviceTransactionRow.Assignee = props.loginUserName;
        } else if (setDefaultAssigneeValue) {
          serviceTransactionRow.AssigneeUID = msalInstance.currentUserId;
          serviceTransactionRow.Assignee = msalInstance.currentUserName;
        } else {
          serviceTransactionRow.AssigneeUID = props.assigneeUID;
          serviceTransactionRow.Assignee = props.assignee;
        }

        serviceTransactionRow.StartDate = props.startDate; // ?? new Date();
        if (isNotNullAndUndefined(props.endDate)) {
          serviceTransactionRow.EndDate = props.endDate;
        }
        if (
          isNotNullAndUndefined(serviceTransactionRow.StartDate) &&
          isNotNullAndUndefined(serviceTransactionRow.EndDate)
        ) {
          serviceTransactionRow.Duration = getTimeDifferenceInMinutes(
            serviceTransactionRow.StartDate,
            serviceTransactionRow.EndDate
          );
        }

        if (IsNotNullOrWhiteSpace(props.relatedTo)) {
          serviceTransactionRow.RelatedTo = props.relatedTo;
        }
        if (IsNotNullOrWhiteSpace(props.relatedToUID)) {
          serviceTransactionRow.RelatedToUID = props.relatedToUID;
        }
        if (IsNotNullOrWhiteSpace(props.relatedToType)) {
          serviceTransactionRow.RelatedToType = props.relatedToType;
        }

        serviceTransactionRow.Title = getServiceTransactionTitleByStartEndDate(
          props.startDate ?? new Date(),
          props.endDate
        );

        // const plannedActivities = await getShiftActivitiesByType(
        //   props.relatedToUID,
        //   [RAFTaskType.ToDo, RAFTaskType.Appointment]
        // );

        const [
          usersAndTeams,
          allForms,
          plannedActivities,
          careInterventionSupportRows,
          tenantSettings,
        ] = await Promise.all([
          initUserAndTeam(),
          getAllFormsByCategoryType(CareEsioEntity.CareShiftLog.DisplayName),
          getPlannedShiftTasksByRelatedToUID(props.relatedToUID),
          getCareInterventionSupportListByCareRecipientUID(
            props.relatedToUID,
            CareEsioEntity.CareInterventionSupport.EntityName
          ),
          getUserTenantSettings(),
        ]);

        const formLibrary = isNotEmptyArray(allForms)
          ? allForms.find(
              (x) => x.Entity === CareEsioEntity.CareShiftActivity.EntityName
            )
          : null;

        setState({
          serviceTransactionRow,
          plannedActivities,
          isLoading: false,
          usersAndTeams,
          tenantSettings,
          formLibrary,
          careInterventionSupportRows,
          noContent: false,
          activeTabIndex: stepperItems[0],
        });
      } else {
        setState({ isLoading: false, noContent: true });
      }
    }
  };

  const onClickSaveBtn = (
    value: ServiceTransactionRow,
    checkIsValidDuration: boolean
  ) => {
    if (isNotNullAndUndefined(rafFormRef) && rafFormRef.invalid === true) {
      showErrorToast("Please provide all the required fields");
      return;
    }
    if (isNotNullAndUndefined(value)) {
      const formValue = R.clone(value);
      const assigneeUID = formValue.AssigneeUID;
      const supportTasks = formValue.SupportTasks;
      const shiftStatus = formValue.Status;

      if (checkIsValidDuration) {
        const startDate = formValue.StartDate;
        const endDate = formValue.EndDate;

        const duration = getTimeDifferenceInMinutes(startDate, endDate);

        //duration greater than 10hrs
        if (duration > 600) {
          showDurationAlertWarningDialog(formValue);
          return;
        }
      } else if (isNotNullAndUndefined(durationAlertWarningDialog)) {
        durationAlertWarningDialog.hide();
      }

      if (props.mode === "SetAssignee" && IsNullOrWhiteSpace(assigneeUID)) {
        showWarningToast("Please select an assignee to save");
        return;
      } else if (props.mode === "Manage" && !isNotEmptyArray(supportTasks)) {
        showWarningToast("Please select at least one support need");
        return;
      } else if (props.mode === "Create" || props.mode === "Edit") {
        if (IsNullOrWhiteSpace(assigneeUID)) {
          if (shiftStatus === RAFShiftActionStatus.Completed) {
            showWarningToast("Please select an assignee to save");
          } else {
            showAssigneAlertWarningDialog(formValue);
          }
          return;
        }
      }

      onEventFormSubmit(formValue);
    }
    if (isNotNullAndUndefined(props.onSave)) {
      props.onSave();
    }
  };

  const showDurationAlertWarningDialog = (formValue: ServiceTransactionRow) => {
    durationAlertWarningDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content:
        "Duration of the shift is greater than 10 hours. Are you sure you want to save?",
      okButton: {
        text: "Yes",
        click: onClickSaveBtn.bind(this, formValue, false),
      },
      title: "Alert!",
      position: { X: "center", Y: "center" },
      cssClass: "raf-teammember_alert_dialog alert-dialog",
    });
  };

  const showAssigneAlertWarningDialog = (formValue: ServiceTransactionRow) => {
    assigneAlertWarningDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: {
        text: "Assign staff",
        click: onClickSwitchToSelectAssignee.bind(this, stepperItems[1]),
      },
      closeOnEscape: false,
      content: "Would you like to leave the shift unassigned?",
      okButton: {
        text: "Confirm unassigned",
        click: onEventFormSubmit.bind(this, formValue),
      },
      title: "Alert!",
      position: { X: "center", Y: "center" },
      cssClass: "raf-teammember_alert_dialog alert-dialog",
    });
  };

  const onEventFormSubmit = async (value: ServiceTransactionRow) => {
    if (isNotNullAndUndefined(assigneAlertWarningDialog)) {
      assigneAlertWarningDialog.hide();
    }
    if (isNotNullAndUndefined(value)) {
      let progressDiv = showProgress(`#${outerDivId}`);

      const serviceTransactionTemplateRow = {
        ...value,
      } as ServiceTransactionRow;

      const isUserExist = true;

      //const careRecipientUID = serviceTransactionTemplateRow.RelatedToUID;
      //const assigneeUID = serviceTransactionTemplateRow.AssigneeUID;
      // const isUserExist = await IsUserExistInCareTeam(
      //   careRecipientUID,
      //   assigneeUID
      // );

      if (isUserExist) {
        const supportTasks = serviceTransactionTemplateRow["SupportTasks"];

        if (props.mode === "Manage" && !isNotEmptyArray(supportTasks)) {
          showWarningToast("Please select at least one support need");
          hideProgress(progressDiv);
          return;
        }

        const plannedActivities =
          serviceTransactionTemplateRow["PlannedActivities"];

        delete serviceTransactionTemplateRow["SupportTasks"];
        delete serviceTransactionTemplateRow["PlannedActivities"];

        serviceTransactionTemplateRow.Title =
          getServiceTransactionTitleByStartEndDate(
            serviceTransactionTemplateRow.StartDate,
            serviceTransactionTemplateRow.EndDate
          );

        if (IsNullOrWhiteSpace(serviceTransactionTemplateRow.AssigneeUID)) {
          serviceTransactionTemplateRow.Status =
            RAFShiftActionStatus.Unassigned;
        } else if (
          state.serviceTransactionRow.Status ===
            RAFShiftActionStatus.Unassigned &&
          !IsNullOrWhiteSpace(serviceTransactionTemplateRow.AssigneeUID)
        ) {
          serviceTransactionTemplateRow.Status = RAFShiftActionStatus.Scheduled;
        }

        saveServiceTransaction(serviceTransactionTemplateRow).then(
          async (serviceTransactionResponse) => {
            //if (mode === "Create") {
            const [careInterventionSupportRows, allPlannedActivities] =
              await Promise.all([
                saveShiftActivityList(
                  serviceTransactionResponse,
                  supportTasks,
                  state.careInterventionSupportRows,
                  state.formLibrary
                ),
                SaveShiftPlannedActivityList(
                  serviceTransactionResponse,
                  plannedActivities,
                  state.plannedActivities
                ),
              ]);

            hideProgress(progressDiv);
            if (props.onSave) {
              props.onSave();
            }
            // } else {
            //   if (props.onSave) {
            //     props.onSave();
            //   }
            //   hideProgress(progressDiv);
            // }
          }
        );
      } else {
        hideProgress(progressDiv);
        careTeamMemberAlertClicked(serviceTransactionTemplateRow);
        return;
      }
    } else {
      showWarningToast("Sorry something went wrong !");
    }
  };

  const careTeamMemberAlertClicked = (
    serviceTransactionTemplateRow: ServiceTransactionRow
  ) => {
    careTeamMemberAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No", click: onAddTeamCancelBtnClick.bind(this) },
      closeOnEscape: false,
      content:
        "The selected user isn't part of the care team at the moment. Would you like to include them? If not, please ensure you select a member from the existing care team.",
      okButton: {
        text: "Yes",
        click: AddCareTeamMember.bind(this, serviceTransactionTemplateRow),
      },
      title: "Alert!",
      position: { X: "center", Y: "center" },
      cssClass: "raf-teammember_alert_dialog alert-dialog",
    });
  };

  const onAddTeamCancelBtnClick = () => {
    const { serviceTransactionRow } = state;
    setFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("State"),
      serviceTransactionRow.State
    );
    careTeamMemberAlertDialog.hide();
  };

  const AddCareTeamMember = async (
    serviceTransactionTemplateRow: ServiceTransactionRow
  ) => {
    let progressDiv = showProgress(".raf-teammember_alert_dialog.e-dialog");

    const responseCareTeamMember = await addUserToCareTeam(
      serviceTransactionTemplateRow.RelatedTo,
      serviceTransactionTemplateRow.RelatedToUID,
      serviceTransactionTemplateRow.AssigneeUID
    );

    if (responseCareTeamMember.response) {
      const supportTasks = serviceTransactionTemplateRow["SupportTasks"];
      const plannedActivities =
        serviceTransactionTemplateRow["PlannedActivities"];

      // let progressDiv = showHeaderAndFooter
      //   ? showProgress(`#${outerDivId}`)
      //   : showProgress("body", true);

      delete serviceTransactionTemplateRow["SupportTasks"];
      delete serviceTransactionTemplateRow["PlannedActivities"];

      serviceTransactionTemplateRow.Title =
        getServiceTransactionTitleByStartEndDate(
          serviceTransactionTemplateRow.StartDate,
          serviceTransactionTemplateRow.EndDate
        );

      if (IsNullOrWhiteSpace(serviceTransactionTemplateRow.AssigneeUID)) {
        serviceTransactionTemplateRow.Status = RAFShiftActionStatus.Unassigned;
      } else if (
        state.serviceTransactionRow.Status ===
          RAFShiftActionStatus.Unassigned &&
        !IsNullOrWhiteSpace(serviceTransactionTemplateRow.AssigneeUID)
      ) {
        serviceTransactionTemplateRow.Status = RAFShiftActionStatus.Scheduled;
      }

      saveServiceTransaction(serviceTransactionTemplateRow).then(
        async (serviceTransactionResponse) => {
          //if (mode === "Create") {
          const [careInterventionSupportRows, allPlannedActivities] =
            await Promise.all([
              saveShiftActivityList(
                serviceTransactionResponse,
                supportTasks,
                state.careInterventionSupportRows,
                state.formLibrary
              ),
              SaveShiftPlannedActivityList(
                serviceTransactionResponse,
                plannedActivities,
                state.plannedActivities
              ),
            ]);
          careTeamMemberAlertDialog.hide();
          hideProgress(progressDiv);
          if (props.onSave) {
            props.onSave();
          }
          //}
          // else {
          //   careTeamMemberAlertDialog.hide();
          //   if (props.onSave) {
          //     props.onSave();
          //   }
          //   hideProgress(progressDiv);
          // }
        }
      );
    } else {
      hideProgress(progressDiv);
      careTeamMemberAlertDialog.hide();
      showWarningToast(responseCareTeamMember.warningMessage);
    }
  };

  const onClickSwitchToDraft = () => {
    if (isNotNullAndUndefined(rafFormRef)) {
      if (rafFormRef.invalid) {
        rafFormRef.form.submit();
      } else {
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("State"),
          ServiceTransactionStates.Draft
        );
        rafFormRef.form.submit();
      }
    }
  };

  const onClickSaveAndPublish = () => {
    if (isNotNullAndUndefined(rafFormRef)) {
      if (rafFormRef.invalid) {
        rafFormRef.form.submit();
      } else {
        // const assigneeUID = getFormValue(
        //   rafFormRef,
        //   propertyOf<ServiceTransactionRow>("AssigneeUID")
        // );
        //if (IsNullOrWhiteSpace(assigneeUID)) {
        //showWarningToast("Please select an assignee to publish");
        //} else {
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("State"),
          ServiceTransactionStates.Published
        );
        rafFormRef.form.submit();
        //}
      }
    }
  };

  const deleteServiceTransactionClicked = (title: string) => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: DeleteServiceTransaction.bind(this) },
      showCloseIcon: false,
      title: title,
      position: { X: "center", Y: "center" },
      cssClass: "raf-delete_alert_dialog alert-dialog",
    });
  };

  const DeleteServiceTransaction = () => {
    let progressDiv = showProgress(".raf-delete_alert_dialog.e-dialog");
    const { serviceTransactionRow } = state;
    DeleteRecordRemoveFocus(
      serviceTransactionRow.UID,
      RAFEntityName.ServiceTransaction
    ).then((response) => {
      deleteDialog.hide();
      hideProgress(progressDiv);
      if (isNotNullAndUndefined(response)) {
        if (props.onDelete) {
          props.onDelete();
        } else if (props.onSave) {
          props.onSave();
        }
      }
    });
  };

  const onChangeStartDateEndDate = () => {
    setTimeout(() => {
      const clientUID = getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("RelatedToUID")
      );
      const startDate = getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("StartDate")
      );
      const endDate = getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("EndDate")
      );
      const serviceContractItemUID = getFormValue(
        rafFormRef,
        "ServiceContractItemUID"
      );
      const shiftType = getFormValue(rafFormRef, "ShiftUID");

      if (isNotNullAndUndefined(serviceContractItemUID)) {
        updateVariantServiceContractItem(
          clientUID,
          serviceContractItemUID,
          startDate,
          endDate,
          shiftType
        );
      }
    }, 500);
  };

  const onSelectServiceContractItem = async (label, serviceContractItemUID) => {
    const startDate = getFormValue(rafFormRef, "StartDate");
    const endDate = getFormValue(rafFormRef, "EndDate");
    const shiftType = getFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("ShiftType")
    );
    if (IsNullOrWhiteSpace(startDate) && IsNullOrWhiteSpace(endDate)) {
      showWarningToast("Please select Start Date and End Date");
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }
    if (IsNullOrWhiteSpace(startDate)) {
      showWarningToast("Please select Start Date");
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }
    if (IsNullOrWhiteSpace(endDate)) {
      showWarningToast("Please select End Date");
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("ServiceContractItem"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("ServiceContractItemUID"),
        null
      );
      return;
    }

    updateVariantServiceContractItem(
      getFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("RelatedToUID")
      ),
      serviceContractItemUID,
      startDate,
      endDate,
      shiftType
    );
  };

  const updateVariantServiceContractItem = async (
    clientUID: string,
    serviceContractItemUID,
    startDate: Date,
    endDate: Date,
    shiftType: string
  ) => {
    if (
      isNotNullAndUndefined(clientUID) &&
      isNotNullAndUndefined(serviceContractItemUID) &&
      isNotNullAndUndefined(startDate) &&
      isNotNullAndUndefined(endDate)
    ) {
      const [variantServiceContractItem, travelVariantLookUpItems] =
        await Promise.all([
          getServiceContractItemVariant(
            serviceContractItemUID,
            startDate,
            endDate,
            shiftType
          ),
          getAllTravelVariantLookUpItems(serviceContractItemUID, shiftType),
        ]);

      if (isNotNullAndUndefined(variantServiceContractItem)) {
        showSuccessToast(
          "Time-specific line Item found for the selected Service Contract Item"
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("Variant"),
          variantServiceContractItem.Name
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("VariantUID"),
          variantServiceContractItem.UID
        );

        if (isNotEmptyArray(travelVariantLookUpItems)) {
          const travelVariantLookUpItem = travelVariantLookUpItems[0];
          setFormValue(
            rafFormRef,
            propertyOf<ServiceTransactionRow>("EnableTravel"),
            true
          );
          setFormValue(
            rafFormRef,
            propertyOf<ServiceTransactionRow>("TravelVariant"),
            travelVariantLookUpItem.Value
          );
          setFormValue(
            rafFormRef,
            propertyOf<ServiceTransactionRow>("TravelVariantUID"),
            travelVariantLookUpItem.UID
          );
        } else {
          setFormValue(
            rafFormRef,
            propertyOf<ServiceTransactionRow>("EnableTravel"),
            null
          );
          setFormValue(
            rafFormRef,
            propertyOf<ServiceTransactionRow>("TravelVariant"),
            null
          );
          setFormValue(
            rafFormRef,
            propertyOf<ServiceTransactionRow>("TravelVariantUID"),
            null
          );
        }
        setState({ variantServiceContractItem });
      } else {
        showWarningToast(
          "No Time-specific line Item found for the selected Service Contract Item"
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("Variant"),
          null
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("VariantUID"),
          null
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("TravelVariant"),
          null
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("TravelVariantUID"),
          null
        );
        setFormValue(
          rafFormRef,
          propertyOf<ServiceTransactionRow>("EnableTravel"),
          null
        );
        setState({ variantServiceContractItem: null });
      }
    } else {
      if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
        showWarningToast("Please select Start Date and End Date");
      }
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("Variant"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("VariantUID"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("TravelVariant"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("TravelVariantUID"),
        null
      );
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("EnableTravel"),
        null
      );
    }
  };

  const getServiceContractItemLookUp = () => {
    const { tenantSettings } = state;
    const isContractItemRequired = isNotNullAndUndefined(tenantSettings)
      ? tenantSettings.ContractItemRequired === "Yes"
        ? true
        : false
      : false;
    return (
      <Field name={propertyOf<ServiceTransactionRow>("RelatedToUID")}>
        {({ input }) => {
          let customFilter: RAFCustomFilter = {};
          customFilter.Condition = "and";
          customFilter.Rules = [];
          if (isNotNullAndUndefined(input.value)) {
            let filter: RAFCustomFilter = {};
            let filterVal: string[] = [];
            filterVal.push(input.value);
            filter.Operator = RAFCustomOperator.Equal;
            filter.Value = filterVal;
            filter.Field = "CareRecipientUID";
            customFilter.Rules.push(filter);
          }

          let parentFilter: RAFCustomFilter = {};
          parentFilter.Operator = RAFCustomOperator.IsNull;
          parentFilter.Field = "ParentUID";
          customFilter.Rules.push(parentFilter);

          return (
            <RAFLookUpMUI<ServiceTransactionRow>
              field={propertyOf<ServiceTransactionRow>("ServiceContractItem")}
              label="Service Contract Item"
              placeholder="Select Service Contract Item"
              url="ServiceContractItem/Lookup"
              moduleName={CareEsioEntity.CareServiceContractItem.EntityName}
              customFilter={customFilter}
              showLabel
              onChanged={(label, value) =>
                onSelectServiceContractItem(label, value)
              }
              closeToolTip={false}
              showFullList={false}
              required={isContractItemRequired}
            />
          );
        }}
      </Field>
    );
  };

  const getServiceTransactionSettings = () => {
    return (
      <>
        <Field name={propertyOf<ServiceTransactionRow>("BillingStatus")}>
          {({ input }) => {
            let status = input.value;
            return (
              <>
                <div className="col-12">
                  {/* <RAFCheckBox
            field={propertyOf<ServiceTransactionRow>("IsBillable")}
            label="Billable?"
            showLabel={true}
            uitype={"switch"}
            //labelPosition="left"
            //labelClassName="BpStpExtSet__customContent col"
            //inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            //onChanged={onChangeEnableTravel}
            labelClassName="col"
            inputFieldClassName="col-auto"
            colorStyle="secondary"
          /> */}
                  <RAFRadioButtonList
                    field={propertyOf<ServiceTransactionRow>("IsBillable")}
                    label="Billable?"
                    showLabel={true}
                    validate={false}
                    uitype="customButton"
                    hideRequiredMessage
                    //labelPosition="right"
                    //labelClassName="col-md-4"
                    inputFieldClassName="col-auto"
                    disabled={
                      status === RAFShiftActionStatus.Ready ? true : false
                    }
                  >
                    <RAFChoiceOption label="Yes" value={true} />
                    <RAFChoiceOption label="No" value={false} />
                  </RAFRadioButtonList>
                </div>
                <div className="col-12">
                  {/* <RAFCheckBox
            field={propertyOf<ServiceTransactionRow>("IsAutoInvoice")}
            label="Auto Invoice?"
            showLabel={true}
            uitype={"switch"}
            //labelPosition="left"
            //labelClassName="BpStpExtSet__customContent col"
            //inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            //onChanged={onChangeEnableTravel}
            labelClassName="col"
            inputFieldClassName="col-auto"
            colorStyle="secondary"
          /> */}
                  <RAFRadioButtonList
                    field={propertyOf<ServiceTransactionRow>("IsAutoInvoice")}
                    label="Add To Invoice?"
                    showLabel={true}
                    validate={false}
                    uitype="customButton"
                    hideRequiredMessage
                    //labelPosition="right"
                    //labelClassName="col-md-4"
                    inputFieldClassName="col-auto"
                    disabled={
                      status === RAFShiftActionStatus.Ready ? true : false
                    }
                  >
                    <RAFChoiceOption label="Yes" value={true} />
                    <RAFChoiceOption label="No" value={false} />
                  </RAFRadioButtonList>
                </div>
              </>
            );
          }}
        </Field>
      </>
    );
  };

  const tabSelected = (selectedTabId) => {
    setState({ selectedTabId });
  };

  const getServiceContractItemVariationLookUp = () => {
    return (
      <Field name={propertyOf<ServiceTransactionRow>("ServiceContractItemUID")}>
        {({ input }) => {
          const serviceContractItemUID = IsNotNullOrWhiteSpace(input.value)
            ? input.value
            : null;
          let additionalParams = [];
          if (isNotNullAndUndefined(input.value)) {
            additionalParams = [
              {
                key: "ParentUID",
                value: serviceContractItemUID,
              },
            ];
          }

          return (
            <>
              <div className="col-12">
                <Field name={propertyOf<ServiceTransactionRow>("ShiftType")}>
                  {({ input }) => {
                    let customFilter: RAFCustomFilter = {};
                    customFilter.Condition = "and";
                    customFilter.Rules = [];
                    if (isNotNullAndUndefined(input.value)) {
                      let filter: RAFCustomFilter = {};
                      let filterVal: string[] = [];
                      filterVal.push(input.value);
                      filter.Operator = RAFCustomOperator.Equal;
                      filter.Value = filterVal;
                      filter.Field = "ShiftType";
                      customFilter.Rules.push(filter);
                    }
                    return (
                      <RAFLookUpMUI<ServiceTransactionRow>
                        field={propertyOf<ServiceTransactionRow>("Variant")}
                        label="Time-specific line Item"
                        placeholder="Select Variations"
                        url="ServiceContractItem/VariantLookup"
                        customFilter={customFilter}
                        moduleName={
                          CareEsioEntity.CareServiceContractItem.EntityName
                        }
                        additionalParams={additionalParams}
                        showLabel
                        closeToolTip={false}
                        showFullList={false}
                        disabled={
                          IsNotNullOrWhiteSpace(serviceContractItemUID)
                            ? false
                            : true
                        }
                      />
                    );
                  }}
                </Field>
                {IsNotNullOrWhiteSpace(serviceContractItemUID) && (
                  <div className="body_3_light mt-2">
                    <RAFDetailsField field="Variant" />
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="custom__card__separator custom__card__separator__light"></div>
              </div>
            </>
          );
        }}
      </Field>
    );
  };

  const onChangeEnableTravel = () => {
    setFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("TravelVariant"),
      null
    );
    setFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("TravelVariantUID"),
      null
    );
  };

  const getServiceContractItemTravelVariantLookup = () => {
    return (
      <>
        <div className="col-12">
          <RAFCheckBox
            field={propertyOf<ServiceTransactionRow>("EnableTravel")}
            label="Enable Travel?"
            showLabel={true}
            uitype={"switch"}
            //labelPosition="left"
            //labelClassName="BpStpExtSet__customContent col"
            //inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            onChanged={onChangeEnableTravel}
            labelClassName="col"
            inputFieldClassName="col-auto"
            colorStyle="secondary"
          />
        </div>
        <Condition
          when={propertyOf<ServiceTransactionRow>("EnableTravel")}
          is={true}
        >
          <div className="col-12">
            <Field
              name={propertyOf<ServiceTransactionRow>("ServiceContractItemUID")}
            >
              {({ input }) => {
                const serviceContractItemUID = IsNotNullOrWhiteSpace(
                  input.value
                )
                  ? input.value
                  : null;
                let additionalParams = [];
                if (isNotNullAndUndefined(input.value)) {
                  additionalParams = [
                    {
                      key: "ParentUID",
                      value: serviceContractItemUID,
                    },
                  ];
                }

                return (
                  <div>
                    <Field
                      name={propertyOf<ServiceTransactionRow>("ShiftType")}
                    >
                      {({ input }) => {
                        let customFilter: RAFCustomFilter = {};
                        customFilter.Condition = "and";
                        customFilter.Rules = [];
                        if (isNotNullAndUndefined(input.value)) {
                          let filter: RAFCustomFilter = {};
                          let filterVal: string[] = [];
                          filterVal.push(input.value);
                          filter.Operator = RAFCustomOperator.Equal;
                          filter.Value = filterVal;
                          filter.Field = "ShiftType";
                          customFilter.Rules.push(filter);
                        }
                        return (
                          <RAFLookUpMUI<ServiceTransactionRow>
                            field={propertyOf<ServiceTransactionRow>(
                              "TravelVariant"
                            )}
                            label="Travel Variant"
                            placeholder="Select Travel Variant"
                            url="ServiceContractItem/GetTravelVariant"
                            customFilter={customFilter}
                            moduleName={
                              CareEsioEntity.CareServiceContractItem.EntityName
                            }
                            additionalParams={additionalParams}
                            showLabel
                            closeToolTip={false}
                            showFullList={false}
                            disabled={
                              IsNotNullOrWhiteSpace(serviceContractItemUID)
                                ? false
                                : true
                            }
                          />
                        );
                      }}
                    </Field>
                  </div>
                );
              }}
            </Field>
          </div>
          <Condition
            when={propertyOf<ServiceTransactionRow>("Type")}
            is={RAFServiceTransactionType.Booking}
          >
            <Condition
              when={propertyOf<ServiceTransactionRow>("Status")}
              is={RAFShiftActionStatus.Completed}
            >
              <div className="col-12">
                <RAFNumber
                  label="Travel KM"
                  field={propertyOf<ServiceTransactionRow>("TravelDistance")}
                />
              </div>
            </Condition>
          </Condition>
        </Condition>
        <div className="col-12">
          <div className="custom__card__separator custom__card__separator__light"></div>
        </div>
        <div className="col-12">
          <RAFCheckBox
            field={propertyOf<ServiceTransactionRow>("IsPassive")}
            label="Is Passive Shift?"
            showLabel={true}
            uitype={"switch"}
            //labelPosition="left"
            //labelClassName="BpStpExtSet__customContent col"
            //inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
            formGroupClassName="mb-0"
            onChanged={onChangeIsPassiveInput}
            labelClassName="col"
            inputFieldClassName="col-auto"
            colorStyle="secondary"
          />
        </div>
      </>
    );
  };

  const onSelectRelatedTo = async (label, value) => {
    if (isNotNullAndUndefined(value) && isNotNullAndUndefined(label)) {
      let progressDiv = showProgress(`#${outerDivId}`);

      const [careInterventionSupportRows, plannedActivities] =
        await Promise.all([
          getCareInterventionSupportListByCareRecipientUID(
            value,
            CareEsioEntity.CareInterventionSupport.EntityName
          ),
          getPlannedShiftTasksByRelatedToUID(value),
        ]);

      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("RelatedToType"),
        CareEsioEntity.CareRecipient.EntityName
      );

      hideProgress(progressDiv);
      setState({
        careInterventionSupportRows,
        plannedActivities,
      });
    } else {
      setFormValue(
        rafFormRef,
        propertyOf<ServiceTransactionRow>("RelatedToType"),
        null
      );
      setState({
        careInterventionSupportRows: null,
        plannedActivities: null,
      });
    }
  };

  const getSupportCardContnet = (supportItem, value: string[]) => {
    if (isNotNullAndUndefined(supportItem)) {
      const { shiftActivities } = state;

      const isSelectedAndDisabled = isNotEmptyArray(shiftActivities)
        ? shiftActivities.some(
            (activity) => activity["support_needuid"] === supportItem.UID
          )
        : false;

      const isChecked = isNotEmptyArray(value)
        ? value.includes(supportItem.UID)
        : false;

      return (
        <>
          {/* <div className="col-12 g-3" key={supportItem.UID}> */}
          <CareInterventionSupportContent
            key={supportItem.UID}
            careInterventionSupportRow={supportItem}
            parent="CreateSupportTask"
            //onSelect={() => onClickSupportTaskItem(supportItem)}
            showIsRequiredBadge={true}
            showCheckBox={true}
            isChecked={isSelectedAndDisabled === true ? true : isChecked}
            isDisabled={isSelectedAndDisabled}
            allowEdit={false}
            onChangeCheckBoxValue={(checked) =>
              onChangeSupportCheckBoxValue(checked, supportItem.UID)
            }
          />
        </>
      );
    } else {
      return null;
    }
  };

  const onChangeSupportCheckBoxValue = (
    isChecked: boolean,
    selectedItemUID: string
  ) => {
    const supportTasks = getFormValue(rafFormRef, "SupportTasks");
    const newSupportTasks = isNotEmptyArray(supportTasks)
      ? [...supportTasks]
      : [];
    if (isChecked) {
      if (!newSupportTasks.includes(selectedItemUID)) {
        newSupportTasks.push(selectedItemUID);
      }
    } else {
      if (newSupportTasks.includes(selectedItemUID)) {
        newSupportTasks.splice(newSupportTasks.indexOf(selectedItemUID), 1);
      }
    }
    setFormValue(rafFormRef, "SupportTasks", newSupportTasks);
  };

  const getPlannerActivityContent = () => {
    //const showPlanner  = (props.mode === "Manage" || props.mode === "Create") ||
    //(props.mode === "Edit" && serviceTransactionRow.State === ServiceTransactionStates.Draft);

    return (
      // <CustomCardWidget removeContentPadding>
      //   <RAFCollapseWithPlusIcon
      //     toggleArrowIcon="Arrow"
      //     title={`Suggestion from planned list`}
      //     headerFixedHeight
      //     collapsePanelContentClassname="p-2 p-md-3"
      //     badge={{
      //       count: isNotNullAndUndefined(state.plannedActivities)
      //         ? state.plannedActivities.length
      //         : 0,
      //       show: true,
      //       className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
      //     }}
      //     isCollapsed={false}
      //     layout={RAFLayout.OneColumnLayout}
      //   >
      <Field name={"PlannedActivities"}>
        {({ input }) => {
          return (
            <div className="row g-0 gy-2">
              {isNotEmptyArray(state.plannedActivities) ? (
                state.plannedActivities.map((item) => {
                  return (
                    <Fragment key={item.UID}>
                      {getPlannedActivityCardContnet(item, input.value)}
                    </Fragment>
                  );
                })
              ) : (
                <RAFEmptyState title="You don't have any planned activities" />
              )}
              {/* <RAFMultiSelectDropdown
                  field={"PlannedActivities"}
                  label="Select required supports"
                  showLabel={false}
                  formGroupClassName={"mb-0"}
                  uitype="checkbox"
                >
                  {state.plannedActivities.map((opt, index) => {
                    return (
                      <RAFChoiceOption
                        key={opt.UID}
                        label={opt.Title}
                        value={opt.UID}
                      />
                    );
                  })}
                </RAFMultiSelectDropdown> */}
            </div>
          );
        }}
      </Field>
      //   </RAFCollapseWithPlusIcon>
      // </CustomCardWidget>
    );
  };

  const getPlannedActivityCardContnet = (plannedActivity, value: string[]) => {
    if (isNotNullAndUndefined(plannedActivity)) {
      const isChecked = isNotEmptyArray(value)
        ? value.includes(plannedActivity.UID)
        : false;
      return (
        <>
          <ShiftActivityCardContent
            key={plannedActivity.UID}
            shiftActivityRow={plannedActivity}
            onChangeCheckBoxValue={(checked) =>
              onChangePlanedCheckBoxValue(checked, plannedActivity.UID)
            }
            showCheckBox={true}
            isChecked={isChecked}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const onChangePlanedCheckBoxValue = (
    isChecked: boolean,
    selectedItemUID: string
  ) => {
    const plannedActivities = getFormValue(rafFormRef, "PlannedActivities");
    const newPlannedActivities = isNotEmptyArray(plannedActivities)
      ? [...plannedActivities]
      : [];
    if (isChecked) {
      if (!newPlannedActivities.includes(selectedItemUID)) {
        newPlannedActivities.push(selectedItemUID);
      }
    } else {
      if (newPlannedActivities.includes(selectedItemUID)) {
        newPlannedActivities.splice(
          newPlannedActivities.indexOf(selectedItemUID),
          1
        );
      }
    }
    setFormValue(rafFormRef, "PlannedActivities", newPlannedActivities);
  };

  const getCarePlanContent = () => {
    //const showPlanner = (props.mode === "Manage" || props.mode === "Create") ||
    //(props.mode === "Edit" && serviceTransactionRow.State === ServiceTransactionStates.Draft);

    return (
      // <CustomCardWidget removeContentPadding>
      //   <RAFCollapseWithPlusIcon
      //     toggleArrowIcon="Arrow"
      //     title={`Suggestion from support needs`}
      //     headerFixedHeight
      //     collapsePanelContentClassname="p-2 p-md-3"
      //     badge={{
      //       count: isNotNullAndUndefined(state.careInterventionSupportRows)
      //         ? state.careInterventionSupportRows.length
      //         : 0,
      //       show: true,
      //       className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
      //     }}
      //     isCollapsed={false}
      //     layout={RAFLayout.OneColumnLayout}
      //   >
      <Field name={"SupportTasks"}>
        {({ input }) => {
          return (
            <div className="row gy-2">
              {isNotEmptyArray(state.careInterventionSupportRows) ? (
                state.careInterventionSupportRows.map((item) => {
                  return (
                    <Fragment key={item.UID}>
                      {getSupportCardContnet(item, input.value)}
                    </Fragment>
                  );
                })
              ) : (
                <RAFEmptyState title="You don't have any support needs" />
              )}
            </div>
          );
        }}
      </Field>
      //   </RAFCollapseWithPlusIcon>
      // </CustomCardWidget>
    );
  };

  const getFormContent = () => {
    return (
      <div id="manage_Task_outerDiv">
        <div className="row gy-3 g-0 flex-column text-align-center form-group-margin-0">
          {getFormBodyContent()}
        </div>
      </div>
    );
  };

  const getFormBodyContent = () => {
    const { activeTabIndex } = state;
    if (activeTabIndex.id === RAFStepperConstant.Home.Id) {
      return getHomeContent();
    } else if (activeTabIndex.id === RAFStepperConstant.SetAssignee.Id) {
      return getAssigneeContent(state.serviceTransactionRow);
    } else if (activeTabIndex.id === RAFStepperConstant.SelectActivities.Id) {
      return getActivitiesContent(state.serviceTransactionRow);
    } else if (activeTabIndex.id === RAFStepperConstant.Billing.Id) {
      return getBillingContent();
    }
  };

  const onChangeIsPassiveInput = (isChecked) => {
    setFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("ShiftType"),
      isChecked
        ? RAFServiceTransactionShiftType.Passive
        : RAFServiceTransactionShiftType.Active
    );
    const clientUID = getFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("RelatedToUID")
    );
    const startDate = getFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("StartDate")
    );
    const endDate = getFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("EndDate")
    );
    const serviceContractItemUID = getFormValue(
      rafFormRef,
      "ServiceContractItemUID"
    );

    updateVariantServiceContractItem(
      clientUID,
      serviceContractItemUID,
      startDate,
      endDate,
      isChecked
        ? RAFServiceTransactionShiftType.Passive
        : RAFServiceTransactionShiftType.Active
    );
  };

  const onChangeStatus = (value) => {
    setFormValue(
      rafFormRef,
      propertyOf<ServiceTransactionRow>("TravelDistance"),
      null
    );
  };

  const getHomeContent = () => {
    let { serviceTransactionRow } = state;
    return (
      <>
        {getRelatedToContent()}
        <div className="col-md-12">
          <RAFStartDateEndDatePicker
            field={propertyOf<ServiceTransactionRow>("StartDate")}
            endDateField={propertyOf<ServiceTransactionRow>("EndDate")}
            label="Start Date"
            required
            formGroupClassName="mb-0"
            interval={15}
            startEndDateDurationHrs={props.startEndDateDurationHrs}
            {...(serviceTransactionRow.Type === RAFServiceTransactionType.Shift
              ? { defaultEndDateInput: "DateTimePicker" }
              : {})}
            onChangeStartDateEndDate={onChangeStartDateEndDate}
            roundOff
          />
        </div>
        <Condition
          when={propertyOf<ServiceTransactionRow>("Type")}
          is={RAFServiceTransactionType.Booking}
        >
          <div className="col-12">
            <RAFDropdownCC
              field={propertyOf<ServiceTransactionRow>("Status")}
              label="Status"
              placeholder="Status"
              moduleName={moduleName}
              allowAdd={false}
              onChanged={(label, value) => onChangeStatus(value)}
              required
            >
              <RAFChoiceOption
                value={RAFShiftActionStatus.Scheduled}
                label={RAFShiftActionStatus.Scheduled}
              />
              <RAFChoiceOption
                value={RAFShiftActionStatus.Completed}
                label={RAFShiftActionStatus.Completed}
              />
            </RAFDropdownCC>
          </div>
        </Condition>
        {false && (
          <div className="col-md-12">
            <RAFCheckBox
              field={propertyOf<ServiceTransactionRow>("EnableTravel")}
              label="Does this shift have travel permission?"
              showLabel={true}
              uitype={"switch"}
              labelPosition="left"
              labelClassName="BpStpExtSet__customContent col"
              inputFieldClassName="BpStpExtSet__label col-auto d-flex align-items-center"
              formGroupClassName="mb-0"
            />
          </div>
        )}
        {/* <div className="col-md-12">
      <RAFLatitudeLongitude
        field={propertyOf<ServiceTransactionRow>(
          "Latitude"
        )}
        label="Location"
        latitudeField={propertyOf<ServiceTransactionRow>(
          "Latitude"
        )}
        longitudeField={propertyOf<ServiceTransactionRow>(
          "Longitude"
        )}
      />
    </div> */}

        <ConditionNotNullOrWhiteSpace
          when={propertyOf<ServiceTransactionRow>("RelatedToUID")}
        >
          <>
            {getServiceContractItemLookUp()}
            {getServiceContractItemVariationLookUp()}
            {getServiceContractItemTravelVariantLookup()}
          </>
        </ConditionNotNullOrWhiteSpace>
      </>
    );
  };

  const getBillingContent = () => {
    return <>{getServiceTransactionSettings()}</>;
  };

  const getAssigneeContent = (serviceTransactionRow: ServiceTransactionRow) => {
    const isDisabled =
      serviceTransactionRow.Status === RAFShiftActionStatus.Completed
        ? IsSuperAdmin()
          ? false
          : true
        : false;
    return <ServiceTransactionSelectAssignee isDisabled={isDisabled} />;
  };

  const getRelatedToContent = () => {
    if (props.showRelatedToLookUp) {
      return (
        <div className="col-md-12">
          <RAFLookUpMUI<ServiceTransactionRow>
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            label="Client"
            placeholder="Select client"
            url="CareRecipient/Lookup"
            lookupGridURL="CareRecipient/List"
            moduleName={CareEsioEntity.CareRecipient.EntityName}
            showLabel
            disabled={
              isNotNullAndUndefined(state.serviceTransactionRow.UID)
                ? true
                : false
            }
            onChanged={(label, value) => onSelectRelatedTo(label, value)}
            closeToolTip={false}
            showFullList={false}
            required
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("ServiceContractItem")}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("ServiceContractItemUID")}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("Variant")}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("VariantUID")}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("TravelVariant")}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("TravelVariantUID")}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={propertyOf<ServiceTransactionRow>("EnableTravel")}
            to={false}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={"SupportTasks"}
            to={null}
          />
          <WhenFieldChanges
            field={propertyOf<ServiceTransactionRow>("RelatedTo")}
            set={"PlannedActivities"}
            to={null}
          />
        </div>
      );
    }
  };

  const renderTabContent = () => {
    if (state.selectedTabId === RAFEntityName.Task) {
      return <div>{getPlannerActivityContent()}</div>;
    } else if (
      state.selectedTabId === CareEsioEntity.CareInterventionSupport.EntityName
    ) {
      return <div>{getCarePlanContent()}</div>;
    }
  };

  const getActivitiesContent = (serviceTransactionRow) => {
    const tabItems: {
      id: string;
      text: string;
    }[] =
      props.mode !== "Manage"
        ? [
            {
              id: RAFEntityName.Task,
              text: "Planned List",
            },
            {
              id: CareEsioEntity.CareInterventionSupport.EntityName,
              text: "Support Needs",
            },
          ]
        : [
            {
              id: CareEsioEntity.CareInterventionSupport.EntityName,
              text: "Support Needs",
            },
          ];
    return (
      <>
        {props.mode !== "Manage" && (
          <>
            <div className="col-md-12">
              <RAFTextArea<ServiceTransactionRow>
                field={propertyOf<ServiceTransactionRow>("Description")}
                showLabel
                label={`${serviceTransactionRow.Type} Instructions`}
                placeholder={`Add notes or instructions for this ${serviceTransactionRow.Type.toLocaleLowerCase()}`}
                rows={3}
              />
            </div>
            {/* {getPlannerActivityContent()} */}
          </>
        )}
        <div
          id="raf_tab_header_service_transaction_action_container"
          className="raf_tab_header raf_lg secondary_tab hideScrollBar full_width_tab border-bottom"
        >
          {!BrowserIsDevice && (
            <RAFButtonComponent
              iconBtn
              iconCss="fas fa-chevron-left"
              className="bg-white border-0 custom-button-lg"
              id="raf_tab_header_service_transaction_action_left_scroll"
              enableToolTip={false}
            ></RAFButtonComponent>
          )}
          <div
            id="raf_tab_header_service_transaction_action"
            className="raf_tab_header raf_lg hideScrollBar"
          >
            {tabItems.map((x) => {
              return (
                <div
                  className={`raf_tab_header_item${
                    state.selectedTabId === x.id ? " active" : ""
                  }`}
                  key={x.id}
                  onClick={() => tabSelected(x.id)}
                >
                  <span>{x.text}</span>
                </div>
              );
            })}
          </div>
          {!BrowserIsDevice && (
            <RAFButtonComponent
              iconBtn
              iconCss="fas fa-chevron-right"
              className="bg-white border-0 custom-button-lg"
              id="raf_tab_header_service_transaction_action_right_scroll"
              enableToolTip={false}
            ></RAFButtonComponent>
          )}
        </div>
        {renderTabContent()}
        {/* {getCarePlanContent()} */}
      </>
    );
  };

  const getDeleteBtnContent = () => {
    const { serviceTransactionRow } = state;
    if (
      isNotNullAndUndefined(serviceTransactionRow) &&
      isNotNullAndUndefined(serviceTransactionRow.UID)
    ) {
      const permissionName =
        serviceTransactionRow.Status === RAFShiftActionStatus.InProgress
          ? ServiceTransactionPermissionConstants.ServiceTransactionDeleteShiftsAfterStart
          : ServiceTransactionPermissionConstants.ServiceTransactionDeleteShiftsBeforeStart;

      const hasPermissionToDelete = IsSuperAdmin()
        ? true
        : hasPermission(permissionValue, permissionName);

      if (hasPermissionToDelete) {
        return (
          <div className="col-auto">
            <RAFButtonComponent
              action={RAFButtonConstant.Delete}
              onClick={() =>
                deleteServiceTransactionClicked(
                  serviceTransactionRow.Type ===
                    RAFServiceTransactionType.Booking
                    ? "Delete Booking"
                    : `Delete ${serviceTransactionDisplayName}`
                )
              }
              className="e-danger e-outline form-custom-button"
              iconBtn
              enableToolTip={false}
            />
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getSaveAsDraftBtnContent = () => {
    const { serviceTransactionRow } = state;
    if (serviceTransactionRow.Type === RAFServiceTransactionType.Shift) {
      if (serviceTransactionRow.State === ServiceTransactionStates.Draft) {
        return (
          <RAFButtonComponent
            type="button"
            action={RAFButtonConstant.SaveAsDraft}
            onClick={() => rafFormRef && rafFormRef.form.submit()}
            idString="CreateContent"
            className="btn_brand_primary outline"
            showIcon={false}
            {...(BrowserIsDevice ? { btnContent: "Draft" } : {})}
          />
        );
      } else if (
        (serviceTransactionRow.Status === RAFShiftActionStatus.Scheduled ||
          serviceTransactionRow.Status === RAFShiftActionStatus.Accepted ||
          serviceTransactionRow.Status === RAFShiftActionStatus.OfferSent ||
          serviceTransactionRow.Status === RAFShiftActionStatus.Unassigned) &&
        serviceTransactionRow.State === ServiceTransactionStates.Published
      ) {
        return (
          <RAFButtonComponent
            type="button"
            action={RAFButtonConstant.SaveAsDraft}
            onClick={() => onClickSwitchToDraft()}
            idString="SwitchToDraft"
            showIcon={false}
            className="btn_brand_primary outline"
            {...(BrowserIsDevice ? { btnContent: "Draft" } : {})}
          />
        );
      } else {
        return <></>;
      }
    } else {
      return null;
    }
  };

  const getDefaultFooterBtn = () => {
    let { serviceTransactionRow } = state;
    return (
      <>
        {/* <div className="col-auto">
          <RAFCheckBox
            field={propertyOf<ServiceTransactionRow>("Notify")}
            label="Notify"
            showLabel={true}
            uitype={'lightCheckbox'}
            labelPosition="right"
            inputFieldClassName="col-auto"
            labelClassName="col"
            formGroupClassName="mb-0"
            rowClassName="row g-2 align-items-center"
          />
        </div> */}
        <div className="col-auto">{getSaveAsDraftBtnContent()}</div>
        <div className="col-auto">
          <RAFButtonComponent
            type="button"
            action={
              serviceTransactionRow.Status ===
                RAFShiftActionStatus.InProgress ||
              serviceTransactionRow.Status === RAFShiftActionStatus.Completed
                ? RAFButtonConstant.Save
                : BrowserIsDevice
                ? RAFButtonConstant.Publish
                : RAFButtonConstant.SaveAndPublish
            }
            onClick={() => onClickSaveAndPublish()}
            idString="CreateContent"
            className="btn_brand_primary semi_dark"
            showIcon={false}
            {...(BrowserIsDevice ? { btnContent: "Publish" } : {})}
          />
        </div>
      </>
    );
  };

  const onClickMoveToNextPageBtn = (activeTabIndex: StepperItemsRow) => {
    if (isNotNullAndUndefined(rafFormRef)) {
      if (rafFormRef.invalid) {
        rafFormRef.form.submit();
      } else {
        setState({ activeTabIndex });
      }
    }
  };

  const onClickBackBtn = (newActiveTabIndex) => {
    setState({
      activeTabIndex: newActiveTabIndex,
    });
  };

  const getFooterBackBtn = () => {
    const { activeTabIndex } = state;
    if (props.mode === "Create" || props.mode === "Edit") {
      return (
        <div className="col-auto">
          <RAFButtonComponent
            type="button"
            action={RAFButtonConstant.Back}
            onClick={() => {
              if (
                activeTabIndex.id === RAFStepperConstant.SelectActivities.Id
              ) {
                onClickSwitchToSelectAssignee(stepperItems[1]);
              } else if (activeTabIndex.id === RAFStepperConstant.Billing.Id) {
                onClickBackBtn(stepperItems[2]);
              } else {
                onClickBackBtn(
                  activeTabIndex.id === RAFStepperConstant.SelectActivities.Id
                    ? stepperItems[1]
                    : stepperItems[0]
                );
              }
            }}
            className="btn_brand_secondary"
            disabled={state.activeTabIndex.index === 0 ? true : false}
            {...(BrowserIsDevice ? { iconBtn: true } : {})}
          />
        </div>
      );
    } else {
      return <div className="col-auto"></div>;
    }
  };

  const onClickSwitchToSelectAssignee = async (
    activeTabIndex: StepperItemsRow
  ) => {
    if (isNotNullAndUndefined(assigneAlertWarningDialog)) {
      assigneAlertWarningDialog.hide();
    }
    if (isNotNullAndUndefined(rafFormRef)) {
      if (rafFormRef.invalid) {
        rafFormRef.form.submit();
      } else {
        setState({ activeTabIndex });
      }
    }
  };

  const onClickSwitchToBilling = async (activeTabIndex: StepperItemsRow) => {
    if (isNotNullAndUndefined(rafFormRef)) {
      if (rafFormRef.invalid) {
        rafFormRef.form.submit();
      } else {
        setState({ activeTabIndex });
      }
    }
  };

  const getFooterSwitchPageBtn = () => {
    const { activeTabIndex } = state;

    if (props.mode === "Create" || props.mode === "Edit") {
      return (
        <div className="col-auto">
          <RAFButtonComponent
            type="button"
            action={RAFButtonConstant.Next}
            onClick={() => {
              if (activeTabIndex.id === RAFStepperConstant.Home.Id) {
                onClickSwitchToSelectAssignee(stepperItems[1]);
              } else if (
                activeTabIndex.id === RAFStepperConstant.SetAssignee.Id
              ) {
                onClickSwitchToSelectAssignee(stepperItems[2]);
              } else {
                onClickMoveToNextPageBtn(stepperItems[3]);
              }
            }}
            className="btn_brand_secondary"
            {...(BrowserIsDevice ? { iconBtn: true } : {})}
            disabled={
              state.activeTabIndex.index === stepperItems.length - 1
                ? true
                : false
            }
            iconPosition="right"
          />
        </div>
      );
    } else {
      return <div className="col-auto"></div>;
    }
  };

  const getFooterContent = () => {
    if (props.mode === "Manage") {
      const hasPermissionToAddActivity = IsSuperAdmin()
        ? true
        : RAFShiftActionStatus.InProgress &&
          hasPermission(
            permissionValue,
            ServiceTransactionPermissionConstants.ServiceTransactionManageShifts
          )
        ? true
        : false;
      return (
        <div className="e-dlg-footerContent">
          <div className="w-100">
            <div className="row gx-2 justify-content-between">
              <div className="col-auto"></div>
              <div className="col-auto">
                <div className="row gx-2">
                  {(isNotEmptyArray(state.careInterventionSupportRows) ||
                    isNotEmptyArray(state.plannedActivities)) &&
                    hasPermissionToAddActivity && (
                      <div className="col-auto">
                        <RAFButtonComponent
                          type="button"
                          isPrimary
                          action={RAFButtonConstant.Save}
                          onClick={() => rafFormRef && rafFormRef.form.submit()}
                          idString="CreateContent"
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      const hasPermissionToEdit =
        // (serviceTransactionRow.Status === RAFShiftActionStatus.Scheduled ||
        //   serviceTransactionRow.Status ===
        //   RAFShiftActionStatus.Unassigned) &&
        hasPermission(
          permissionValue,
          ServiceTransactionPermissionConstants.ServiceTransactionManageShifts
        )
          ? true
          : false;
      return (
        <div className="e-dlg-footerContent">
          <div className="w-100">
            <div className="row gx-2 justify-content-between align-items-center">
              {getFooterBackBtn()}
              <div className="col-auto">
                <div className="row gx-2 align-items-center">
                  {(props.mode === RAFStepperConstant.SetAssignee.Id ||
                    state.activeTabIndex.id !== "SelectAssignee") &&
                    getDeleteBtnContent()}
                  {hasPermissionToEdit && getDefaultFooterBtn()}
                </div>
              </div>
              {getFooterSwitchPageBtn()}
            </div>
          </div>
          {/* <div className="w-100">
            <div className="row gx-2 justify-content-between">
              <div className="col-auto">
                <div className="row gx-2">
                  {getFooterBackBtn()}
                  {(props.mode === RAFStepperConstant.SetAssignee.Id || state.activeTabIndex.id !== 'SelectAssignee') && (
                    getDeleteBtnContent()
                  )}
                </div>
              </div>
              <div className="col-auto">
                <div className="row gx-2">
                  {hasPermissionToEdit && (
                    getDefaultFooterBtn()
                  )}
                </div>
              </div>
              <div className="col-auto">
                <div className="row gx-2">
                  {hasPermissionToEdit && (
                    getFooterSwitchPageBtn()
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      );
    }
  };

  const onSelectStepperItem = (activeTabIndex: StepperItemsRow) => {
    if (activeTabIndex.id === RAFStepperConstant.SetAssignee.Id) {
      onClickSwitchToSelectAssignee(activeTabIndex);
    } else if (
      activeTabIndex.id === RAFStepperConstant.SelectActivities.Id ||
      activeTabIndex.id === RAFStepperConstant.Home.Id ||
      activeTabIndex.id === RAFStepperConstant.Billing.Id
    ) {
      onClickMoveToNextPageBtn(activeTabIndex);
    }
  };

  const getStepperContent = () => {
    if (props.mode !== "Manage" && props.mode !== "SetAssignee") {
      const { activeTabIndex } = state;

      return (
        <div className="px-3">
          <div className="stepper-content active_avatar_text w-100 py-3 border-bottom">
            <ul
              className="stepper-div w-100 hideScrollBar stepper-grey"
              id="stepperDiv"
            >
              {stepperItems.map((item, index) => {
                const activeTabIndexIndex = isNotNullAndUndefined(
                  activeTabIndex
                )
                  ? activeTabIndex.index
                  : null;
                const isActive = index === activeTabIndexIndex ? true : false;
                const isCompleted = index < activeTabIndexIndex ? true : false;
                return (
                  <li
                    id={`stepDiv_Page_${item.id}`}
                    key={`stepDiv_Page_${item.id}`}
                    className={`stepper-item${
                      isActive === true ? " stepper-inProgress-item" : ""
                    }${isCompleted === true ? " stepper-completed-item" : ""}`}
                    onClick={() => {
                      onSelectStepperItem(item);
                    }}
                  >
                    <span className="stepper-item-avatar">
                      {isCompleted ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          fill="none"
                        >
                          <path
                            d="M12.3346 1L5.0013 8.33333L1.66797 5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      ) : !isActive ? (
                        index + 1
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8.83958 2.39982L3.36624 8.19315C3.15958 8.41315 2.95958 8.84649 2.91958 9.14649L2.67291 11.3065C2.58624 12.0865 3.14624 12.6198 3.91958 12.4865L6.06624 12.1198C6.36624 12.0665 6.78624 11.8465 6.99291 11.6198L12.4662 5.82649C13.4129 4.82649 13.8396 3.68649 12.3662 2.29315C10.8996 0.913152 9.78624 1.39982 8.83958 2.39982Z"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.92578 3.3667C8.21245 5.2067 9.70578 6.61337 11.5591 6.80003"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2 14.6665H14"
                            stroke="white"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      )}
                    </span>
                    <span className="ecllipseFirstLine word-break-all">
                      {item.text}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      const { serviceTransactionRow } = state;
      if (isNotNullAndUndefined(serviceTransactionRow)) {
        if (props.mode === "Manage" && props.isNestedDlg) {
          return (
            <>
              <RAFEntityProvider moduleName={moduleName}>
                <RAFAttributeRelatedListProvider
                  moduleName={moduleName}
                  progressDivId={`#manage_Task_outerDiv`}
                >
                  <RAFForm
                    type={ServiceTransactionRow}
                    initialValues={serviceTransactionRow}
                    formRef={(g) => {
                      return (rafFormRef = g);
                    }}
                    layout={RAFLayout.TwoColumnLayout}
                    submitOnEnterKey={false}
                    onSubmit={(value) => onClickSaveBtn(value, true)}
                    className="h-100"
                  >
                    <div className="e-dlg-body-content">{getFormContent()}</div>
                    {getFooterContent()}
                  </RAFForm>
                </RAFAttributeRelatedListProvider>
              </RAFEntityProvider>
            </>
          );
        } else {
          return (
            <div className="h-100" key={moduleName} id={outerDivId}>
              <RAFEntityProvider moduleName={moduleName}>
                <RAFAttributeRelatedListProvider
                  moduleName={moduleName}
                  progressDivId={`#manage_Task_outerDiv`}
                >
                  <RAFForm
                    type={ServiceTransactionRow}
                    initialValues={serviceTransactionRow}
                    formRef={(g) => {
                      return (rafFormRef = g);
                    }}
                    layout={RAFLayout.TwoColumnLayout}
                    submitOnEnterKey={false}
                    onSubmit={(value) => onClickSaveBtn(value, true)}
                    className="h-100"
                  >
                    <div className="e-dlg-content-outer manage_service_transaction_container">
                      {getStepperContent()}
                      <div className="e-dlg-body-content">
                        {getFormContent()}
                      </div>
                      {getFooterContent()}
                    </div>
                  </RAFForm>
                </RAFAttributeRelatedListProvider>
              </RAFEntityProvider>
            </div>
          );
        }
      } else if (state.noContent === true) {
        return (
          <div className="e-dlg-content-outer">
            <div className="p-3 py-2 columnChooserPanel customScrollBar">
              <RAFDeletedRecordState title="You do not have sufficient privileges to edit this item." />
            </div>
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageServiceTransaction);

export class ServiceTransactionTemplateRow {
  UID?: string;
  Title?: string;
  StartDate?: Date;
  EndDate?: Date;
  //Duration?: number;
  Type?: string;
  Status?: string;
  AssigneeUID?: string;
  Assignee?: string;
  AssigneeType?: string;
  RelatedTo?: string;
  RelatedToUID?: string;
  RelatedToType?: string;
  AssignTeam?: string;
  AssignTeamUID?: string;
  Priority?: string;
  SortOrder?: number;
  Description?: string;
  SecondaryRelatedToUID?: string;
  SecondaryRelatedToType?: string;
  SecondaryRelatedTo?: string;
  ParentUID?: string;
  Parent?: string;
  ServiceTransactionIdNumber?: number;
  ServiceTransactionId?: string;
  CronExpression?: string;
  RRuleExpression?: string;
  TagsListJson?: string[];
  Portal?: string;
  PortalUID?: string;
  ServiceContractItem?: string;
  ServiceContractItemUID?: string;
  ServiceName?: string;
  ServiceCode?: string;
  Latitude?: string;
  Longitude?: string;
  State?: string;
  GroupUID?: string;
  IsRequired?: boolean;
  IncludePublicHoliday?: boolean;
  Frequency?: string;
  CreatedDate?: Date;
  CreatedByUID?: string;
  CreatedBy?: string;
  ModifiedDate?: Date;
  ModifiedByUID?: string;
  ModifiedBy?: string;
  RecordOwnerUID?: string;
  RecordOwner?: string;
  LastActivityDate?: Date;
  Variant?: string;
  VariantUID?: string;
}
